import Login from "../screens/nonAuth/Login";
import Dashboard from "../screens/auth/Dashboard";
import Setting from "../screens/auth/Setting";

import Home from "../screens/users/home/Home";


export const authRoutes = [
  {
    path: '/dashboard',
    component: <Dashboard />,
  },
  {
    path: '/settings',
    component: <Setting />,
  },
];

export const nonAuthRoutes = [
  {
    path: '/login',
    component: <Login />,
  },
];

export const userRoutes = [
  {
    path: '/',
    component: <Home />,
  },
 
];